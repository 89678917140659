import { ReactNode } from "react";
import styled from "styled-components";
import tw from "twin.macro";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loadColors = () => {
  tw`bg-gray-700`;
};

interface StyledLoaderProps {
  fullPage?: boolean;
}

const StyledLoader = styled.div<StyledLoaderProps>`
  ${({ fullPage }) => (fullPage ? tw`fixed z-[2000]` : tw`absolute z-[1000]`)}
  ${tw`inset-0 w-full h-screen overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center`}

  .loader {
    border-top-color: white;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }

  .title {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: #eeeeee;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ children, fullPage = false }: { children?: ReactNode; fullPage?: boolean }) => {
  return (
    <StyledLoader fullPage={fullPage}>
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" />
      <span className="text-center text-white text-xl font-semibold title">
        {children ?? "Chargement en cours ..."}
      </span>
    </StyledLoader>
  );
};

export default Loader;
